export class Dom {

  /**
   * Вызов callback функции по событию
   * @param {string} eventNames
   * @param element
   * @param callback
   */
  public static ready(eventNames: string, element: Document, callback) {
    eventNames.split(' ').forEach(e => element.addEventListener(e, (event) => {
      callback(event);
    }));
  }

  /**
   * Возвращает все элементы по названию класса
   * @param {string} selector
   * @param callback
   */
  public static getElementsByClass(selector: string, callback) {
    const elements: any = document.getElementsByClassName(selector);
    for (const element of elements) {
      callback(element);
    }
  }

  /**
   * Возвращает элемент в callback функцию по id
   * @param {string} id
   * @param callback
   */
  public static getElementById(id: string, callback) {
    const element: any = document.getElementById(id);
    if (element !== null) {
      callback(element);
    }
  }

  /**
   * Возвращает все элементы (по селектору)
   * @param {string} selector
   * @param callback
   */
  public static querySelector(selector: string, callback) {
    const elements: any = document.querySelectorAll(selector);
    for (const element of elements) {
      if (element !== null) {
        callback(element);
      }
    }
  }

  /**
   * Вставить новый элемент перед другим элементом
   * @param child
   * @param parent
   */
  public static prepend(child, parent) {
    return parent.insertBefore(child, parent.firstChild);
  }

  /**
   * Вставить новый элемент после другого элемента
   * @param child
   * @param parent
   */
  public static append(child, parent) {
    return parent.appendChild(child);
  }

  /**
   * Создает элемент
   * @param {string} tagName
   * @param {string|null} contents
   * @param {string|array} className
   * @param {string} id
   * @returns {HTMLElement}
   */
  public static createElement(tagName: string, contents: any = null, className: any = null, id: string = null) {
    const element = document.createElement(tagName);
    if (typeof className === 'string') {
      element.className = className;
    } else if (className instanceof Array) {
      element.className = className.join(' ');
    }
    if (typeof contents === 'string') {
      element.innerHTML = contents;
    }
    if (id) {
      element.id = id;
    }

    return element;
  }

  /**
   * Добавление нескольких дочерних элементов родителю
   * @param {HTMLElement} parent
   * @param {HTMLElement[]} children
   * @param {boolean|function} callback
   */
  public static appendChildren(parent: HTMLElement, children: HTMLElement[], callback: any = false) {
    for (const child of children) {
      const element = parent.appendChild(child);
      if (typeof callback === 'function') {
        callback(element);
      }
    }
  }

  /**
   * @param {string} name
   * @param {string|null} url
   * @returns {string|boolean}
   */
  public static getPathParam(name, url = null) {
    if (!url) {
      url = location.pathname;
    }
    const r = new RegExp('\/' + name + '\/([^\/]+)', 'i');
    const match = url.match(r);
    return (match && match.length > 0) ? match[1] : false;
  }

  /**
   * Показ элемента с анимацией fadeIn
   * @param element
   * @param {string} display
   */
  public static fadeIn(element, display = 'block') {
    element.style.opacity = 0;
    element.style.display = display;

    (function fade() {
      let val = parseFloat(element.style.opacity);
      if (!((val += .1) > 1)) {
        element.style.opacity = val;
        requestAnimationFrame(fade);
      }
    })();
  }

  /**
   * Скрытие элемента с анимацией fadeOut
   * @param element
   */
  public static fadeOut(element) {
    element.style.opacity = 1;

    (function fade() {
      if ((element.style.opacity -= .1) >= 0) {
        requestAnimationFrame(fade);
      } else {
        element.style.display = 'none';
      }
    })();
  }

  /**
   * Удаление элемента
   * @param element
   */
  public static remove(element: HTMLElement): any {
    if (element === null) {
      return;
    }
    element.parentNode.removeChild(element);
  }

  /**
   * Удаление элемента по селектору
   * @param selector
   */
  public static removeSelector(selector: string = '') {
    const s = document.querySelector(selector);
    if (s instanceof HTMLElement) {
      this.remove(s);
    }
  }

  /**
   * Добавление класса к элементу
   * @param {HTMLElement} element
   * @param {string} className
   */
  public static addClass(element: HTMLElement, className: string) {
    if (!element.classList) {
      element.className += ' ' + className;
    } else {
      element.classList.add(className);
    }
  }

  /**
   * Удаление класса у элемента
   * @param {HTMLElement} element
   * @param {string} className
   */
  public static removeClass(element: HTMLElement, className: string) {
    if (!element.classList) {
      element.className = element.className.replace(
        new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' '
      );
    } else {
      element.classList.remove(className);
    }
  }

  /**
   * Проверка на класс у элемента
   * @param {HTMLElement} element
   * @param {string} className
   * @returns {boolean}
   */
  public static hasClass(element: HTMLElement, className: string): boolean {
    if (element.classList) {
      return element.classList.contains(className);
    }

    return new RegExp('(^| )' + className + '( |$)', 'gi').test(element.className);
  }

  /**
   * Отображение элемента
   * @param {HTMLElement} element
   */
  public static show(element: HTMLElement) {
    element.style.display = '';
  }

  /**
   * Скрытие элемента
   * @param {HTMLElement} element
   */
  public static hide(element: HTMLElement) {
    element.style.display = 'none';
  }

  /**
   * Проверка нахождения на странице с демками компонентов
   * @returns {boolean}
   */
  public static isDemoPage(): boolean {
    return Dom.hasClass(document.querySelector('body'), 'aristos-ariflex-component-index');
  }
}
