import {AbstractElement} from '../base/src/abstract-element';
import {Dom} from '../base/src/utils/dom';
import {Translate} from '../base/src/translate';
import {Popup} from '../popup/entry';
import axios from 'axios';
import * as qs from 'qs';
import {TranslateNamespace} from '../base/src/translate/StorageFactory';

const translator = new Translate(TranslateNamespace.AddToCart);

export class AddToCart extends AbstractElement {

  public static on(): void {
    Dom.querySelector('form.addtocart-form', (element) => {
      if (element.getAttribute('data-atc-binded'))
        return;

      const addCart = new AddToCart(element);
      element.addEventListener('submit', (e) => addCart.submit(e));
      element.setAttribute('data-atc-binded', '1');
    });

    Dom.querySelector('form.addtocart-form-gifting', (element) => {
      if (element.getAttribute('data-atc-binded'))
        return;

      const addCart = new AddToCart(element);
      element.addEventListener('submit', (e) => addCart.submit(e));
      element.setAttribute('data-atc-binded', '1');
    });
  }

  public submit(e): boolean {
    e.preventDefault();
    this.event.trigger('addtocart.beforeSend', null);
    const form = e.currentTarget;
    const button = form.querySelector('button[type="submit"]');
    const data = {};
    const formAction = form.getAttribute('action');
    const prefix = form.dataset.prefix ? form.dataset.prefix : '';
    const cartUrlWithPrefix = prefix + '/checkout/cart/';

    if (form.querySelector('.btn-add-to-cart_order')) {
      setTimeout(() => window.location.href = cartUrlWithPrefix, 50);
      return false;
    }

    const gifting = Dom.hasClass(button, 'btn-add-to-cart_gifting');

    button.disabled = true;
    button.innerText = translator.__('Processing');
    Dom.addClass(button, 'btn-add-to-cart_waiting');
    Dom.addClass(button, 'btn-add-to-cart_order');
    const url = formAction.replace('checkout/cart/', 'checkout/index/');
    if (Dom.getPathParam('action', url)) {
      window.location.href = cartUrlWithPrefix;
      return false;
    }

    const productId = Dom.getPathParam('product', url) || Dom.getPathParam('id', url);
    if (productId) {
      let qty = 1;
      const addQtyElement = form.querySelector('input[name="qty"]');
      if (addQtyElement) {
        qty = addQtyElement.value;
      }

      const payload = new FormData();
      const inputs = form.elements;
      for (const input of inputs) {
          if (input.type === 'submit' || input.name === 'qty') {
              continue;
          }
          payload.append(input.name, input.value);
      }
      payload.append('qty', qty.toString());
      payload.append('gifting', gifting ? '1' : '0');
      payload.append('product', productId);
      payload.append('add', '1');

      axios.post(url, payload).then((response: any) => {
        const json: any = response.data;
        const hidePopup = json.hide_popup_without_related;
        const relatedProductCount = json.related_product_count;

        if (!(hidePopup && relatedProductCount === 0)) {
            const popup = new Popup('tingle-modal_related');
            if (!document.querySelector('.tingle-modal_related .popup')) {
                const {addToCartPopupTitle, popupClosePosition, popupIsCloseOnTouch} = window.storeConfig;
                if (addToCartPopupTitle) {
                    popup.setTitle(addToCartPopupTitle);
                }
                if (popupClosePosition) {
                    popup.setClosePosition(popupClosePosition);
                }
                if (popupIsCloseOnTouch) {
                    popup.setIsCloseOnTouch(popupIsCloseOnTouch);
                }
                popup.setIsDestroyOnClose(true);
                popup.setContent(json.html);
                popup.addFooterButton(translator.__('Continue Shopping'), 'tingle-btn tingle-btn_continue',
                    () => popup.close()
                );
                popup.addFooterButton(translator.__('View Shopping Cart'), 'tingle-btn tingle-btn_cart',
                    () => setTimeout(() => window.location.href = cartUrlWithPrefix, 100)
                );
                popup.addEventOpen(() => {
                    Dom.querySelector('.popup__close', (elem) => {
                        elem.addEventListener('click', () => popup.close());
                    });
                    Dom.querySelector('.tingle-btn_continue', (elem) => {
                        elem.addEventListener('click', () => popup.close());
                    });
                    AddToCart.on();
                });
                popup.show();
            }
        }

        button.disabled = false;
        button.innerText = translator.__('Proceed to Checkout');
        this.event.trigger('addtocart.complete', json);
        const event = new CustomEvent('addtocart.complete', {detail: json});
        document.dispatchEvent(event);
        Dom.removeClass(button, 'btn-add-to-cart_waiting');
      }).catch((error) => {
        console.error('Error adding to card', error.message || error);
        form.submit();
      });
    } else {
      console.warn('Product id not found');
      form.submit();
    }
    return false;
  }

}
AddToCart.on();

document.addEventListener('aristos::addToCartOn', () => {
    AddToCart.on();
});
