import {TranslateNamespace, createStorage} from './translate/StorageFactory';

declare const getFormConfig: any;

export class Translate {

    /**
     * Get current language
     */
    public static getLanguage(): string {
        return this.language;
    }

    /**
     * Set language
     * @param language
     */
    public static setLanguage(language: string) {
        Translate.language = language;
    }

    private static language: string = '';
    private readonly namespace: TranslateNamespace;

    /**
     * Constructor
     * @param namespace
     */
    constructor(namespace: TranslateNamespace) {
        if (!Translate.getLanguage()) {
            try {
                Translate.setLanguage(document.documentElement.lang);
            } catch (e) {
                console.error('Error init translator', e);
                Translate.setLanguage('en');
            }
        }

        this.namespace = namespace;
    }

    /**
     * Get translate
     * @param word
     */
    public __(word = ''): string {
        const translate = createStorage(this.namespace);

        return translate.getTranslate(Translate.language, word);
    }
}
