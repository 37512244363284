import {BaseStorage} from './BaseStorage';
import {AddToCartStorage} from './AddToCartStorage';

export interface Storage {
  dictionary: Map<string, Map<string, string>>;

  getTranslate(language: string, word: string): string;
}

export enum TranslateNamespace {
  Base,
  AddToCart,
}

export function createStorage(namespace: TranslateNamespace): Storage {
  // TODO:: кэшировать инстансы

  switch (namespace) {
    case TranslateNamespace.Base:
      return new BaseStorage();
    case TranslateNamespace.AddToCart:
      return new AddToCartStorage();
  }
}
