import {Storage} from "./StorageFactory";
import {BaseStorage} from "./BaseStorage";

export class AddToCartStorage extends BaseStorage implements Storage {
  dictionary: Map<string, Map<string, string>> = new Map([
    ["ru", new Map([
      ["Proceed to Checkout", "Оформить"],
      ["View Shopping Cart", "Перейти в корзину"],
      ["Continue Shopping", "Продолжить покупки"],
      ["Processing", "Добавляю"],
    ])],
    ["en", new Map([
      ["Proceed to Checkout", "Proceed to Checkout"],
      ["View Shopping Cart", "View Shopping Cart"],
      ["Continue Shopping", "Continue Shopping"],
      ["Processing", "Processing"],
    ])],
    ["nl", new Map([
      ["Proceed to Checkout", "Kopen"],
      ["View Shopping Cart", "Naar winkelwagen"],
      ["Continue Shopping", "Verder winkelen"],
      ["Processing", "Wordt toegevoegd"],
    ])]
  ]);
}
