import {Storage} from "./StorageFactory";

export class BaseStorage implements Storage {
  dictionary: Map<string, Map<string, string>>;
  dictionaryBase: Map<string, Map<string, string>> = new Map([
    ["ru", new Map([
      ["Hello", "Привет"],
    ])],
    ["en", new Map([
      ["Hello", "Hello"],
    ])]
  ]);

  constructor() {
    // TODO: докинуть переводы с мадженты
  }

  /**
   * Get translate
   * @param language
   * @param word
   */
  public getTranslate(language: string = '', word: string = ''): string {
    let translatedWord = BaseStorage.searchTranslate(language, word, this.dictionary);

    if (!translatedWord) {
      translatedWord = BaseStorage.searchTranslate(language, word, this.dictionaryBase);
    }

    return !translatedWord ? word : translatedWord;
  }

  /**
   * Search translate
   * @param language
   * @param word
   * @param dictionary
   */
  private static searchTranslate(language: string, word: string, dictionary: Map<string, Map<string, string>>): string {
    if (dictionary && dictionary.has(language)) {
      let foundedDictionary = dictionary.get(language);

      if (foundedDictionary.has(word)) {
        return foundedDictionary.get(word);
      }
    }

    return '';
  }
}
